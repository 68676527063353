import {useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {UploadOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, DatePicker, Input, message, Modal, Progress, Space, Table, Upload} from 'antd';
import type {RcFile} from 'antd/es/upload/interface';
import dayjs from 'dayjs';
import {smsFiles, smsFilesVariables} from 'graphql/__generated__/smsFiles';
import {upload, uploadVariables} from 'graphql/__generated__/upload';
import { MUTATION_UPLOAD} from 'graphql/delivery';
import { MUTATION_PARSE_SMS_FILE, QUERY_SMS_FILES} from 'graphql/sms-info';
import _ from 'lodash';
import { toSmsFile } from 'routes/routes';

import {parseSmsFile, parseSmsFileVariables} from '../../../../graphql/__generated__/parseSmsFile';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Files.module.scss'

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const SmsFiles = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const [found, setFound] = useState<number>();
    const [created, setCreated] = useState<number>();
    const [errors, setErrors] = useState<JSON>();
    const [open, setOpen] = useState<boolean>(false);

    const query = useQuery<smsFiles, smsFilesVariables>(QUERY_SMS_FILES, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            },
            paginationRecords: {
                limit:  10000
            },
        },
        fetchPolicy: 'no-cache',
    })

    const beforeUpload = (file: RcFile) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
        if (!isExcel) {
            message.error('Только файлы в формате XLS и XLSX');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('Изображение должно быть меньше 10Мб');
        }
        return isExcel && isLt10M;
    };
    const [upload, uploadData] = useMutation<upload, uploadVariables>(MUTATION_UPLOAD)
    const [parseSmsFile, parseSmsFileData] = useMutation<parseSmsFile, parseSmsFileVariables>(MUTATION_PARSE_SMS_FILE)

    const dataSource = _.map(query.data?.smsFiles?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        filename: item.attributes?.media.data?.attributes?.name!,
        recordsLength: item.attributes?.sms_infos?.data.length,
        recordsSendedPercent: Math.round(_.filter(item.attributes?.sms_infos?.data, (r) => (r.attributes?.sendStatus === 'SENDED_2')).length / item.attributes?.sms_infos?.data.length! * 100),
        recordsErrorPercent: Math.round(_.filter(item.attributes?.sms_infos?.data, (r) => (r.attributes?.sendStatus === 'ERROR_3')).length / item.attributes?.sms_infos?.data.length! * 100),
        status: 'test',
    }))

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Название',
            dataIndex: 'filename',
            key: 'filename',
            render: (_:any, record:any) => (<Link to={toSmsFile(record.key)}>{record.filename}</Link>),
        },
        {
            title: 'Количество',
            dataIndex: 'recordsLength',
            key: 'recordsLength',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (item:any, record:any) => (
                <>
                    <Progress percent={record.recordsSendedPercent + record.recordsErrorPercent} success={{ percent: record.recordsSendedPercent }} />
                    {/*{record.recordsSendedPercent > 0 && <Progress type={'circle'} size={32} percent={record.recordsSendedPercent} />}*/}
                    {/*{record.recordsErrorPercent > 0 && <Progress type={'circle'} size={32} percent={record.recordsErrorPercent} strokeColor={'#ff0000'}/>}*/}
            </>)
        },
    ];

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={true}
                                                value={[filters.period && filters.period[0], filters.period && filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    {parseSmsFileData.loading
                        ?
                        <Button type={'primary'} loading>Распознавание файла...</Button>
                        :
                    <Upload
                        multiple={false}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={async ({file}) => {
                            await upload({
                                variables: {file: file},
                                onCompleted: async (upl) => {
                                    await parseSmsFile({
                                        variables: {
                                            data: {
                                                media: upl.upload.data?.id
                                            }
                                        },
                                        onCompleted: async (data) => {
                                            setFound(data.parseSmsFile?.found!)
                                            setCreated(data.parseSmsFile?.created!)
                                            setErrors(data.parseSmsFile?.errors!)
                                            setOpen(true)
                                            await sleep(2000)
                                            query.refetch()                                        }
                                    })
                                },
                            });
                        }}>
                        <Button type={'primary'} icon={<UploadOutlined/>} loading={uploadData.loading}>Загрузить реестр</Button>
                    </Upload>
                    }
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}/>
            <Modal open={open} onCancel={() => setOpen(false)} onOk={() => setOpen(false)}>
                <div>Найдено: <span className={'bold'}>{found}</span></div>
                <div>Создано: <span className={'bold'}>{created}</span></div>
                {errors && _.map(JSON.parse(JSON.stringify(errors)), (item, index) => (
                    <pre className={'caption danger'}>
                            {index+1} {item.details?.errors[0]?.path[0]} {item.message}
                            </pre>
                ))
                }
            </Modal>

        </>

    )

}