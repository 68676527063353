import {CSVLink} from 'react-csv';
import { useSearchParams} from 'react-router-dom';
import {FileExcelOutlined, FileOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table, Tooltip} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
    ENUM_DELIVERYRECORD_EMAILSTATUS,
    ENUM_DELIVERYRECORD_SENDSTATUS,
    ENUM_DELIVERYRECORD_STATUS
} from '../../../__generated__/global-types';
import {fileUrl} from '../../../components/fileUrl';
import {deliveryRecords, deliveryRecordsVariables} from '../../../graphql/__generated__/deliveryRecords';
import {generateRecordFile, generateRecordFileVariables} from '../../../graphql/__generated__/generateRecordFile';
import {
    MUTATION_GENERATE_RECORD_FILE,
    QUERY_DELIVERY_RECORDS
} from '../../../graphql/delivery';
import {CurrencyFormat} from '../../../helpers/CurrencyFormat';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Records.module.scss'

export const Records = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<deliveryRecords, deliveryRecordsVariables>(QUERY_DELIVERY_RECORDS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            },
        },
        fetchPolicy: 'no-cache',
    })

    const [generate] = useMutation<generateRecordFile, generateRecordFileVariables>(MUTATION_GENERATE_RECORD_FILE)

    function displayStatus(status: ENUM_DELIVERYRECORD_STATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_DELIVERYRECORD_STATUS.CREATED:
                name = '🕓 Создан';
                break;
            case ENUM_DELIVERYRECORD_STATUS.FUNDED:
                name = '✅ Оплачен';
                break;
            case ENUM_DELIVERYRECORD_STATUS.CANCELLED:
                name = '❌ Отменен';
                break;
            case ENUM_DELIVERYRECORD_STATUS.REFUNDED:
                name = '🔵 Возврат';
                break;
        }
        return name
    }
    function displaySendStatus(status: ENUM_DELIVERYRECORD_SENDSTATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_DELIVERYRECORD_SENDSTATUS.CREATED_1:
                name = '🕓 Создано';
                break;
            case ENUM_DELIVERYRECORD_SENDSTATUS.SENDED_2:
                name = '✅ Отправлено';
                break;
            case ENUM_DELIVERYRECORD_SENDSTATUS.ERROR_3:
                name = '❌ Ошибка';
                break;
            case ENUM_DELIVERYRECORD_SENDSTATUS.DO_NOT_SEND_4:
                name = '—';
                break;
        }
        return name
    }

    function displayEmailStatus(status: ENUM_DELIVERYRECORD_EMAILSTATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_DELIVERYRECORD_EMAILSTATUS.AWAIT_1:
                name = '';
                break;
            case ENUM_DELIVERYRECORD_EMAILSTATUS.CREATED_2:
                name = '🕓 Создано';
                break;
            case ENUM_DELIVERYRECORD_EMAILSTATUS.SENDED_3:
                name = '✅ Отправлено';
                break;
            case ENUM_DELIVERYRECORD_EMAILSTATUS.ERROR_4:
                name = '❌ Ошибка';
                break;
        }
        return name
    }

    const dataSource = _.map(query.data?.deliveryRecords?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        CertDate: dayjs(item.attributes?.CertDate).format('DD.MM.YYYY'),
        Birthday: dayjs(item.attributes?.Birthday).format('DD.MM.YYYY'),
        sendStatus: displaySendStatus(item.attributes?.sendStatus!),
        InsuranceAmount: CurrencyFormat(item.attributes?.InsuranceAmount!),
        Cost: CurrencyFormat(item.attributes?.Cost!),
        fileUrl: item.attributes?.CertFilename && fileUrl(item.attributes?.CertFilename),
        activated: item.attributes?.activated && `✅ ${dayjs(item.attributes.activatedAt).format('DD.MM.YYYY HH:mm')}`,
        emailStatus: displayEmailStatus(item.attributes?.emailStatus!),
        status: displayStatus(item.attributes?.status!),
    }))

    const columns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Номер сертификата',
            dataIndex: 'CertNumber',
            key: 'CertNumber',
        },
        {
            title: 'Дата',
            dataIndex: 'CertDate',
            key: 'CertDate',
        },
        {
            title: 'Телефон',
            dataIndex: 'Phone',
            key: 'Phone',
            render: (Phone:string) => (<span>📞 {Phone}</span>)
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'SMS Активация',
            dataIndex: 'sendStatus',
            key: 'sendStatus',
        },
        {
            title: 'Активация',
            dataIndex: 'activated',
            key: 'activated',
        },
        {
            title: 'Файл сертификата',
            dataIndex: 'CertFilename',
            key: 'CertFilename',
            render: (_:any, record:any) => {
                if (record.fileUrl) {
                    return (
                        <a href={record.fileUrl!} target={'_blank'} rel={'noreferrer'}>{record.CertFilename}</a>
                    )
                } else {
                    return (
                        <Button type={'link'} onClick={() => onGenerateFile(record.key)}>Создать</Button>
                    )
                }
            }
        },
        {
            title: 'Статус Email',
            dataIndex: 'emailStatus',
            key: 'emailStatus',
        },

    ];

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    const onFileClear = () => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            file: null,
        }))
    }

    const onGenerateFile = async (id: string) => {
        await generate({
            variables: {id: id},
            onCompleted: () => {query.refetch()}
        })
    }

    const headers = [
        {
            label: 'ID',
            key: 'key',
        },
        {
            label: 'Партнер',
            key: 'agent',
        },
        {
            label: 'Продукт',
            key: 'product',
        },
        {
            label: 'Номер сертификата',
            key: 'CertNumber',
        },
        {
            label: 'Дата',
            key: 'CertDate',
        },
        {
            label: 'ФИО',
            key: 'FIO',
        },
        {
            label: 'Паспорт',
            key: 'Passport',
        },
        {
            label: 'Дата рождения',
            key: 'Birthday',
        },
        {
            label: 'Телефон',
            key: 'Phone',
        },
        {
            label: 'Страховая сумма',
            key: 'InsuranceAmount',
        },
        {
            label: 'Страховая премия',
            key: 'Cost',
        },
        {
            label: 'Срок страхования',
            key: 'Duration',
        },
        {
            label: 'Статус сделки',
            key: 'status',
        },
        {
            label: 'SMS Активации',
            key: 'sendStatus',
        },
        {
            label: 'Активации',
            key: 'activated',
        },
        {
            label: 'Файл сертификата',
            key: 'fileUrl',
        },
        {
            label: 'Статус отправки файла',
            key: 'emailStatus',
        },


    ]

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={true}
                                                value={[filters.period && filters.period[0], filters.period && filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                        {filters.file && <Tooltip title={'Убрать фильтр по файлу'}><Button icon={<FileOutlined />} onClick={onFileClear}>{filters.file}</Button></Tooltip>}
                    </Space>
                </div>
                <div className={cls.right}>
                    <span
                        className={cn(['caption', dataSource.length < query.data?.deliveryRecords?.meta.pagination.total! && 'danger'])}><Tooltip
                        title={'Отображается'}>{dataSource.length}</Tooltip> / <Tooltip
                        title={'Всего в базе'}>{query.data?.deliveryRecords?.meta.pagination.total}</Tooltip></span>
                    <CSVLink data={dataSource} headers={headers} filename={'FTS-Sales.csv'} separator={';'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}
                   expandable={{
                       expandedRowRender: (record) => (
                           <div className={cls.expanded}>
                               <ul className={cls.list}>
                                   <li><span>Продукт:</span> {record.product}</li>
                                   <li><span>Партнер:</span> {record.agent}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Страховая сумма:</span> {record.InsuranceAmount}</li>
                                   <li><span>Страховая премия:</span> {record.Cost}</li>
                                   <li><span>Срок страхования:</span> {record.Duration}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Дата рождения:</span> {record.Birthday}</li>
                                   <li><span>Паспорт:</span> {record.Passport}</li>
                               </ul>
                           </div>
                       ),
                   }}
            />

        </>

    )

}