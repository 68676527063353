import {CSVLink} from 'react-csv';
import {Link, useSearchParams} from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {getMoneyKzRegs, getMoneyKzRegsVariables} from '../../../../graphql/__generated__/getMoneyKzRegs';
import {QUERY_GETMONEY_KZ_REGS} from '../../../../graphql/getmoney-kz';
import { toGetMoneyKzUser } from '../../../../routes/routes';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './GetMoneyKZRegs.module.scss'

export const GetMoneyKZRegs = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<getMoneyKzRegs, getMoneyKzRegsVariables>(QUERY_GETMONEY_KZ_REGS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            }
        }
    })

    const dataSource = _.map(query.data?.getMoneyKzRegs?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        birthday: dayjs(item.attributes?.birthday).format('DD.MM.YYYY'),
        sex: item.attributes?.sex === 'male' ? 'Мужской' : 'Женский',
    }))

    const columns = [
        {
            title: 'Дата регистрации',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
            render: (value:string, record:any) => <Link to={toGetMoneyKzUser(record.key)}>{value}</Link>,
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Дата рождение',
            dataIndex: 'birthday',
            key: 'birthday',
        },
        {
            title: 'Пол',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: 'Город',
            dataIndex: 'city',
            key: 'city',
        },

    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'GetMoney-KZ-regs.csv'} separator={';'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading} />
           
        </>

    )

}