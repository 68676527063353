import React from 'react';
import {Link} from 'react-router-dom';
import {Divider} from 'antd';
import cn from 'classnames'
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {
    ACTIVATE,
    CONSULTATION,
    DELIVERY_FILES, GETMONEY_KZ,
    LOSS,
    ONLYCASH_KZ,
    ONLYCASH_RU,
    PARTNER, RECORDS,
    REFUND, SFTP, SMS_FILES, SMS_INFOS
} from 'routes/routes';

import GetMoneyLogo from './Logo/GetMoneyLogoCrm.svg'
import OnlyCashLogo from './Logo/OnlyCash-Logo.svg'

import cls from './Home.module.scss'

export const Home = observer(() => (
    <div className={cls.home}>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Сделки</Divider>
            <Link to={`${RECORDS}?start=${dayjs().startOf('month').format('YYYY-MM-DD')}&end=${dayjs().endOf('month').format('YYYY-MM-DD')}`} className={cn([cls.card, cls.bgTwo])}>Сертификаты</Link>
            <Link to={`${SMS_INFOS}?start=${dayjs().startOf('month').format('YYYY-MM-DD')}&end=${dayjs().endOf('month').format('YYYY-MM-DD')}`} className={cn([cls.card, cls.bgTwo])}>SMS Информирование</Link>
        </div>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Заявки с сайта</Divider>
            <Link to={`${REFUND}?start=${dayjs().startOf('month').format('YYYY-MM-DD')}&end=${dayjs().endOf('month').format('YYYY-MM-DD')}`} className={cls.card}>Расторжения</Link>
            <Link to={CONSULTATION} className={cn([cls.card])}>Консультации</Link>
            <Link to={PARTNER} className={cn([cls.card])}>Сотрудничество</Link>
            <Link to={ACTIVATE} className={cn([cls.card])}>Активации</Link>
            <Link to={LOSS} className={cn([cls.card])}>Убытки</Link>
        </div>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Партнёры</Divider>
            <Link to={DELIVERY_FILES} className={cn([cls.card, cls.bgTwo])}>Рассылки сертификатов</Link>
            <Link to={SMS_FILES} className={cn([cls.card, cls.bgTwo])}>Реестры SMS Информирование</Link>
            <Link to={SFTP} className={cn([cls.card, cls.bgTwo])}>SFTP</Link>
        </div>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Проекты</Divider>
            <Link to={ONLYCASH_KZ} className={cn([cls.card, cls.bgThree])}>
                <div><img src={OnlyCashLogo} alt={'Only Cash logo'}/></div>
                <div>OnlyCash KZ</div>
            </Link>
            <Link to={ONLYCASH_RU} className={cn([cls.card, cls.bgThree])}>
                <div><img src={OnlyCashLogo} alt={'Only Cash logo'}/></div>
                <div>OnlyCash РФ</div>
            </Link>
            <Link to={GETMONEY_KZ} className={cn([cls.card, cls.bgThree])}>
                <div><img src={GetMoneyLogo} alt={'Get Money logo'}/></div>
                <div>Get Money KZ</div>
            </Link>
        </div>

    </div>
))