import {CSVLink} from 'react-csv';
import { useSearchParams} from 'react-router-dom';
import { FileExcelOutlined, FileOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Popconfirm, Space, Switch, Table, Tooltip} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import {
    ENUM_SMSINFO_SENDSTATUS, ENUM_SMSINFO_STATUS, ENUM_SMSSCHEDULE_STATUS
} from '../../../__generated__/global-types';
import {smsInfos, smsInfosVariables} from '../../../graphql/__generated__/smsInfos';
import {updateSmsInfo, updateSmsInfoVariables} from '../../../graphql/__generated__/updateSmsInfo';
import {MUTATION_UPDATE_SMS_INFO, QUERY_SMS_INFOS} from '../../../graphql/sms-info';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Infos.module.scss'

export const Infos = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<smsInfos, smsInfosVariables>(QUERY_SMS_INFOS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            },
            schedulePagination: {
                limit: 100
            },
        },
        fetchPolicy: 'no-cache',
    })

    const [update] = useMutation<updateSmsInfo,updateSmsInfoVariables>(MUTATION_UPDATE_SMS_INFO)
    function displayStatus(status: ENUM_SMSINFO_STATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_SMSINFO_STATUS.CREATED:
                name = '🕓 Создан';
                break;
            case ENUM_SMSINFO_STATUS.FUNDED:
                name = '✅ Оплачен';
                break;
            case ENUM_SMSINFO_STATUS.CANCELLED:
                name = '❌ Отменен';
                break;
            case ENUM_SMSINFO_STATUS.REFUNDED:
                name = '🔵 Возврат';
                break;
        }
        return name
    }
    function displaySendStatus(status: ENUM_SMSINFO_SENDSTATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_SMSINFO_SENDSTATUS.CREATED_1:
                name = '🕓 Создано';
                break;
            case ENUM_SMSINFO_SENDSTATUS.SENDED_2:
                name = '✅ Отправлено';
                break;
            case ENUM_SMSINFO_SENDSTATUS.ERROR_3:
                name = '❌ Ошибка';
                break;
            case ENUM_SMSINFO_SENDSTATUS.DO_NOT_SEND_4:
                name = '🔵 Не отправляется';
                break;
        }
        return name
    }

    function displayScheduleStatus(status: ENUM_SMSSCHEDULE_STATUS) {
        if (!status) return ''
        let name = ''
        switch (status) {
            case ENUM_SMSSCHEDULE_STATUS.CREATED_1:
                name = '🕓 Создано';
                break;
            case ENUM_SMSSCHEDULE_STATUS.SENDED_2:
                name = '✅ Отправлено';
                break;
            case ENUM_SMSSCHEDULE_STATUS.ERROR_3:
                name = '❌ Ошибка';
                break;
        }
        return name
    }
    

    const dataSource = _.map(query.data?.smsInfos?.data, item => ({
        ...item.attributes,
        key: item.id,
        status: displayStatus(item.attributes?.status!),
        date: dayjs(item.attributes?.date).format('DD.MM.YYYY'),
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        sendStatus: displaySendStatus(item.attributes?.sendStatus!),
        Activated: item.attributes?.activated && `✅ ${dayjs(item.attributes.activatedAt).format('DD.MM.YYYY HH:mm')}`,
    }))

    const onChangeSending = async (value: boolean, id: string) => {
        await update({
            variables: {
                id: id,
                data: {sending: value},
            },
            onCompleted: () => {
                query.refetch()
            },
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: 'Партнер',
            dataIndex: 'agent',
            key: 'agent',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Договор',
            dataIndex: 'contract',
            key: 'contract',
        },
        {
            title: 'Дата договора',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Срок',
            dataIndex: 'duration',
            key: 'duration',
            render: (duration:number) => (<span>{duration} мес</span>)
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: (phone:string) => (<span>📞 {phone}</span>)
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'SMS Активация',
            dataIndex: 'sendStatus',
            key: 'sendStatus',
        },
        {
            title: 'Активация',
            dataIndex: 'Activated',
            key: 'Activated',
        },
        {
            title: 'Отправка',
            dataIndex: 'sending',
            key: 'sending',
            render: (_:any, record:any) => (
                <Popconfirm
                    title="Рассылка SMS"
                    description={`Вы уверены что хотите ${record.sending ? 'выключить' : 'включить'} рассылку?`}
                    onConfirm={() => onChangeSending(!record.sending, record.key)}
                    okText="Да"
                    cancelText="Нет"
                >
                    <Switch value={record.sending!} disabled={!record.activated}/>
                </Popconfirm>
            )
        },

    ];

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    const onFileClear = () => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            file: null,
        }))
    }

    const headers = [
        {
            label: 'ID',
            key: 'UID',
        },
        {
            label: 'ФИО',
            key: 'FIO',
        },
        {
            label: 'Телефон',
            key: 'phone',
        },
        {
            label: 'Дата договора',
            key: 'date',
        },
        {
            label: 'Срок',
            key: 'duration',
        },
        {
            label: 'Статус SMS',
            key: 'sendStatus',
        },
        {
            label: 'Активация',
            key: 'Activated',
        },


    ]

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={true}
                                                value={[filters.period && filters.period[0], filters.period && filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                        {filters.file && <Tooltip title={'Убрать фильтр по файлу'}><Button icon={<FileOutlined />} onClick={onFileClear}>{filters.file}</Button></Tooltip>}
                    </Space>
                </div>
                <div className={cls.right}>
                    <span
                        className={cn(['caption', dataSource.length < query.data?.smsInfos?.meta.pagination.total! && 'danger'])}><Tooltip
                        title={'Отображается'}>{dataSource.length}</Tooltip> / <Tooltip
                        title={'Всего в базе'}>{query.data?.smsInfos?.meta.pagination.total}</Tooltip></span>
                    <CSVLink data={dataSource} headers={headers} filename={'FTS-SMS-Info.csv'} separator={';'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}
                   expandable={{
                       expandedRowRender: (record) => (
                           <div className={cls.expanded}>
                               {_.map(record.sms_schedules?.data, item => (
                                   <div className={cls.line}>
                                       <div>{item.attributes?.date}</div>
                                       <div>{item.attributes?.text}</div>
                                       <div>{displayScheduleStatus(item.attributes?.status!)}</div>
                                   </div>
                               ))}
                           </div>
                       ),
                       rowExpandable: (record) => record.sms_schedules?.data.length! > 0,
                   }}

            />

        </>

    )

}