import {CSVLink} from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {ENUM_ONLYCASHKZLEAD_STATUS} from '../../../../__generated__/global-types';
import {onlyCashKzLeads, onlyCashKzLeadsVariables} from '../../../../graphql/__generated__/onlyCashKzLeads';
import {QUERY_ONLYCASH_KZ_LEADS} from '../../../../graphql/onlycash-kz-lead';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './OnlyCashKZLead.module.scss'

export const OnlyCashKZLead = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<onlyCashKzLeads, onlyCashKzLeadsVariables>(QUERY_ONLYCASH_KZ_LEADS, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            }
        }
    })

    function displayBoolean(value: boolean | null | undefined) {
        if (value === null || value === undefined) return '—'
        return value ? '✅ Да' : '❌ Нет'
    }

    function displayDate(value: string | null | undefined) {
        if (value === null || value === undefined) return '—'
        return dayjs(value).format('DD.MM.YYYY HH:mm')
    }


    function displayStatus(status: ENUM_ONLYCASHKZLEAD_STATUS) {

        if (!status) return ''

        let name = ''
        switch (status) {
            case ENUM_ONLYCASHKZLEAD_STATUS.pending:
                name = '🕓 В обработке';
                break;
            case ENUM_ONLYCASHKZLEAD_STATUS.pending_sent:
                name = '🕓📤 В обработке';
                break;
            case ENUM_ONLYCASHKZLEAD_STATUS.rejected:
                name = '❌ Отклонено';
                break;
            case ENUM_ONLYCASHKZLEAD_STATUS.rejected_sent:
                name = '❌📤 Отклонено';
                break;
            case ENUM_ONLYCASHKZLEAD_STATUS.approved:
                name = '✅ Принято';
                break;
            case ENUM_ONLYCASHKZLEAD_STATUS.approved_sent:
                name = '✅📤 Принято';
                break;
        }

        return name
    }

    // function displayReg(lead: onlyCashKzs_onlyCashKzs_data_attributes_lead|null|undefined) {
    //     return lead ? <Link to={'/'}>{lead.data?.id}</Link> : ''
    // }

    const dataSource = _.map(query.data?.onlyCashKzLeads?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        checkCodeAt: displayDate(item.attributes?.checkCodeAt),
        sendCodeAt: displayDate(item.attributes?.sendCodeAt),
        status: displayStatus(item.attributes?.status!),
        uniqPhone: displayBoolean(item.attributes?.uniqPhone!),
        inRandom: displayBoolean(item.attributes?.inRandom!),
    }))

    const columns = [
        {
            title: 'Дата посещений',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'UID',
            dataIndex: 'UID',
            key: 'UID',
        },
        {
            title: 'ID Транзакции',
            dataIndex: 'click_id',
            key: 'click_id',
        },
        {
            title: 'Дата отправки кода',
            dataIndex: 'sendCodeAt',
            key: 'sendCodeAt',
        },
        {
            title: 'Дата входа',
            dataIndex: 'checkCodeAt',
            key: 'checkCodeAt',
        },
        {
            title: 'Уникальный номер',
            dataIndex: 'uniqPhone',
            key: 'uniqPhone',
        },
        {
            title: 'Попал в 10%',
            dataIndex: 'inRandom',
            key: 'inRandom',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'OnlyCash-KZ.csv'} separator={';'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}
                   expandable={{
                       expandedRowRender: (record) => (
                           <div className={cls.expanded}>
                               {_.map(record.logs!.data, (log) => (
                                   <div key={log.id} className={cls.log}>
                                       <div>{dayjs(log.attributes!.createdAt).format('DD.MM.YYYY HH:mm')}</div>
                                       <div>{log.attributes!.URL}</div>
                                       <div>{log.attributes!.response}</div>
                                   </div>
                               ))}
                           </div>
                       ),
                       rowExpandable: (record) => record.logs!.data.length > 0,
                   }}
            />
        </>

    )

}