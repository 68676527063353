import dayjs, {Dayjs} from 'dayjs';

import {RefundRequestFiltersInput} from '../../../__generated__/global-types';


export interface IRefundFilter {
    search: string | null
    period: Dayjs[] | null
    status: string | null
}

export const defaultIndexFilter: IRefundFilter = {
    search: null,
    period: null,
    status: null,
}

export const isChangedFilter = (filter: IRefundFilter) => {
    if (filter.search !== defaultIndexFilter.search) return true
    if (filter.period !== defaultIndexFilter.period) return true
    if (filter.status !== defaultIndexFilter.status) return true
}

export const filtersToSearchParams = (filter: IRefundFilter) => ({
    ...(filter.search && {search: filter.search}),
    ...(filter.period && {start: filter.period[0].format('YYYY-MM-DD'), end: filter.period[1].format('YYYY-MM-DD')}),
    ...(filter.status && {status: filter.status}),
})

export const searchParamsToFilters = (params: URLSearchParams) => ({
    search: params.get('search') ? params.get('search') : defaultIndexFilter.search,
    period: params.get('start') && params.get('end') ? [dayjs(params.get('start')), dayjs(params.get('end'))] : defaultIndexFilter.period,
    status: params.get('status') ? params.get('status') : defaultIndexFilter.status,
}) as IRefundFilter

export const filtersToQuery = (filter: IRefundFilter) => ({
    ...(filter.search && {
        or: [{
            FIO: {contains: filter.search}
        }, {
            Email: {contains: filter.search}
        }, {
            Phone: {contains: filter.search}
        }, {
            Certificate_Number: {contains: filter.search}
        }, {
            id: {contains: filter.search}
        },]
    }),
    ...(filter.period && {createdAt: {between: [filter.period[0].format(), filter.period[1].format()]}}),
    ...(filter.status && {
        status: {contains: filter.status}
    }),
}) as RefundRequestFiltersInput

