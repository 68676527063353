import {useState} from 'react';
import {CSVLink} from 'react-csv';
import {useSearchParams} from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, Checkbox, DatePicker, Input, Select, Space, Table, Tooltip} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';

import {ENUM_REFUNDREQUEST_STATUS, ENUM_REFUNDREQUEST_TYPE} from '../../../__generated__/global-types';
import {fileUrl} from '../../../components/fileUrl';
import {refundRequests, refundRequestsVariables} from '../../../graphql/__generated__/refundRequests';
import {
    updateManyRefundRequest,
    updateManyRefundRequestVariables
} from '../../../graphql/__generated__/updateManyRefundRequest';
import {QUERY_REFUND, UPDATE_MANY_REFUND_REQUESTS} from '../../../graphql/refund';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Refund.module.scss'

function displayStatus(status: ENUM_REFUNDREQUEST_STATUS) {
    if (!status) return ''
    let name = ''
    switch (status) {
        case ENUM_REFUNDREQUEST_STATUS.NEW_1:
            name = '🕓 Создана';
            break;
        case ENUM_REFUNDREQUEST_STATUS.READY_2:
            name = '➡️ Готова';
            break;
        case ENUM_REFUNDREQUEST_STATUS.WORK_3:
            name = '👤 В работе';
            break;
        case ENUM_REFUNDREQUEST_STATUS.AWAIT_4:
            name = '⏸ На паузе';
            break;
        case ENUM_REFUNDREQUEST_STATUS.DONE_5:
            name = '✅ Завершена';
            break;
        case ENUM_REFUNDREQUEST_STATUS.ARCHIVE_6:
            name = 'В архиве';
            break;
    }
    return name
}

export const Refund = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);
    const [chenageStatus, setChenageStatus] = useState(false);
    const [updateMany, {loading}] = useMutation<updateManyRefundRequest,updateManyRefundRequestVariables>(UPDATE_MANY_REFUND_REQUESTS)

    const query = useQuery<refundRequests, refundRequestsVariables>(QUERY_REFUND, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            pagination: {
                limit: 10000
            },
            sort: ['id:DESC']
        }
    })

    //todo при выгрузке обновлять статус

    const dataSource = _.map(query.data?.refundRequests?.data, item => ({
        ...item.attributes,
        key: item.id,
        Certificate_Number: item.attributes?.Certificate_Number,
        Certificate_Date: dayjs(item.attributes?.requestData.Certificate_Date).format('DD.MM.YYYY'),
        Passport: item.attributes?.requestData.Passport,
        Bank_Name: item.attributes?.requestData.Bank_Name,
        Bank_Number: item.attributes?.requestData.Bank_Number,
        Bank_Account: item.attributes?.requestData.Bank_Account,
        Bank_Correspondent: item.attributes?.requestData.Bank_Correspondent,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        file: item.attributes?.requestPdfFileUrl && fileUrl(item.attributes?.requestPdfFileUrl),
        sign: item.attributes?.signStatus === 'SIGNED_2' ? `✅ ${dayjs(item.attributes?.signDate).format('DD.MM.YYYY HH:mm:ss')}` : '—',
        status: displayStatus(item.attributes?.status!),
    }))

    const columns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Дата заявки',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },

        // {
        //     title: 'VIP',
        //     dataIndex: 'vip',
        //     key: 'vip',
        // },
        // {
        //     title: 'Оплата',
        //     dataIndex: 'payment',
        //     key: 'payment',
        // },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Телефон',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'Номер сертификата',
            dataIndex: 'Certificate_Number',
            key: 'Certificate_Number',
        },
        {
            title: 'Подписано',
            dataIndex: 'sign',
            key: 'sign',
        },
        // {
        //     title: 'Ответ',
        //     dataIndex: 'template',
        //     key: 'template',
        // },
        {
            title: 'Заявление',
            dataIndex: 'file',
            key: 'file',
            render: (url:string) => url && (<a href={url} target={'_blank'} rel={'noreferrer'}>Скачать</a>),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
        // {
        //     title: 'Фото',
        //     dataIndex: 'Photo',
        //     key: 'Photo',
        //     render: (url:string) => url && <Image height={32} src={url}/>,
        // },
    ];

    const headers = [
        {
            label: 'ID',
            key: 'key',
        },
        {
            label: 'Дата заявки',
            key: 'createdAt',
        },
        {
            label: 'ФИО',
            key: 'FIO',
        },
        {
            label: 'Телефон',
            key: 'Phone',
        },
        {
            label: 'Email',
            key: 'Email',
        },
        {
            label: 'Номер сертификата',
            key: 'Certificate_Number',
        },
        {
            label: 'Дата выдачи',
            key: 'Certificate_Date',
        },
        {
            label: 'Паспорт',
            key: 'Passport',
        },
        {
            label: 'Наименование банка',
            key: 'Bank_Name',
        },
        {
            label: 'БИК',
            key: 'Bank_Number',
        },
        {
            label: 'Номер счета',
            key: 'Bank_Account',
        },
        {
            label: 'Корр счет',
            key: 'Bank_Correspondent',
        },
        {
            label: 'Ответ',
            key: 'template',
        },
        {
            label: 'Подписано',
            key: 'sign',
        },
        {
            label: 'Код подписания',
            key: 'signCode',
        },
        {
            label: 'Статус отправки кода',
            key: 'signSendStatus',
        },
        {
            label: 'Заявление',
            key: 'file',
        },
        {
            label: 'Статус',
            key: 'status',
        },

    ]

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    const onStatusChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            status: data,
        }))
    }

    const onDownloadCSV = async () => {
        if (chenageStatus) {
            await updateMany({
                variables: {
                    data: {
                        id: _.map(dataSource, item => item.key!),
                        status: ENUM_REFUNDREQUEST_STATUS.WORK_3,
                    }
                },
                onCompleted: () => {
                    setChenageStatus(false);
                    query.refetch();
                }
            })
        }
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={true}
                                                value={[filters.period && filters.period[0], filters.period && filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                        <Select onChange={onStatusChange} value={filters.status} popupMatchSelectWidth={false}>
                            <Select.Option value={null}>Все</Select.Option>
                            <Select.Option value="NEW_1">🕓 Созданы</Select.Option>
                            <Select.Option value="READY_2">➡️ Готовы</Select.Option>
                            <Select.Option value="WORK_3">👤 В работе</Select.Option>
                            <Select.Option value="AWAIT_4">⏸ На паузе</Select.Option>
                            <Select.Option value="DONE_5">✅ Завершены</Select.Option>
                            <Select.Option value="ARCHIVE_6">В архиве</Select.Option>
                        </Select>

                    </Space>
                </div>
                <div className={cls.right}>
                    <Checkbox checked={chenageStatus} onChange={(e) => setChenageStatus(e.target.checked)}>Взять в работу</Checkbox>
                    <span className={cn(['caption', dataSource.length < query.data?.refundRequests?.meta.pagination.total! && 'danger'])}><Tooltip title={'Отображается'}>{dataSource.length}</Tooltip> / <Tooltip title={'Всего в базе'}>{query.data?.refundRequests?.meta.pagination.total}</Tooltip></span>
                    <CSVLink data={dataSource} headers={headers} filename={'FTS-Refund.csv'} separator={';'}>
                        <Button loading={loading} icon={<FileExcelOutlined/>} onClick={onDownloadCSV}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}
                   expandable={{
                       expandedRowRender: (record) => (
                           <div className={cls.expanded}>
                               <ul className={cls.list}>
                                   <li><span>ФИО:</span> {record.FIO}</li>
                                   <li><span>Email:</span> {record.Email}</li>
                                   <li><span>Телефон:</span> {record.Phone}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Паспорт:</span> {record.Passport}</li>
                                   <li><span>Сертификат:</span> {record.Certificate_Number}</li>
                                   <li><span>Дата выдачи:</span> {record.Certificate_Date}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Банк:</span> {record.Bank_Name}</li>
                                   <li><span>БИК:</span> {record.Bank_Number}</li>
                                   <li><span>Номер счета:</span> {record.Bank_Account}</li>
                                   <li><span>Корр счет:</span> {record.Bank_Correspondent}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Подписано:</span> {record.signStatus === 'CREATED_1' ? 'Нет' : 'Да'}</li>
                                   <li><span>Код подписания:</span> {record.signCode}</li>
                                   <li><span>Дата подписания:</span> {record.signDate ? dayjs(record.signDate).format('DD.MM.YYYY HH:mm:ss') : '—'}</li>
                                   <li><span>Статус отправки кода:</span> {record.signSendStatus}</li>
                               </ul>
                           </div>
                       ),
                       rowExpandable: (record) => record.type === ENUM_REFUNDREQUEST_TYPE.free,
                   }}
            />

        </>

    )

}