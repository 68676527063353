import {gql} from '@apollo/client';
export const QUERY_SMS_FILES = gql`
    query smsFiles($filters: SmsFileFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $paginationRecords: PaginationArg = {}) {
        smsFiles(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    media {
                        data {
                            id
                            attributes {
                                url
                                name
                            }
                        }
                    }
                    sms_infos (pagination: $paginationRecords) {
                        data {
                            id
                            attributes {
                                sendStatus
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;

export const QUERY_SMS_INFOS = gql`
    query smsInfos($filters: SmsInfoFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $schedulePagination: PaginationArg = {},) {
        smsInfos(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    status
                    UID
                    FIO
                    phone
                    date
                    contract
                    duration
                    rowData
                    sendStatus
                    activated
                    activatedAt
                    sending
                    agent
                    sms_schedules (pagination: $schedulePagination) {
                        data {
                            id
                            attributes {
                                text
                                date
                                status
                                log
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;




export const MUTATION_CREATE_SMS_FILE = gql`
    mutation createSmsFile($data: SmsFileInput!) {
        createSmsFile(data: $data) {
            data {
                id
            }
        }
    }
`;

export const MUTATION_PARSE_SMS_FILE = gql`
    mutation parseSmsFile($data: SmsFileInput!) {
        parseSmsFile(data: $data) {
            found
            created
            errors
        }
    }
`;

export const MUTATION_UPDATE_SMS_INFO = gql`
    mutation updateSmsInfo($id: ID!, $data: SmsInfoInput!) {
        updateSmsInfo(id: $id, data: $data) {
            data {
                id
            }
        }
    }
`;
